<template>
    <div class="verify_account">
        <div class="area_content">
            <div class="logo">
                <img src="@/assets/images/logo2.svg" alt="Logo">
            </div>
            <h2>Change mobile phone</h2>
            <Form class="verify_form" @submit="handleChangePhone" v-slot="{ errors }">
                <div class="setting_wpr">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Mobile Phone</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.mobile_number }">
                                <Field autocomplete="off" name="mobile_number" type="text" v-model="form.mobile_number" rules="required" placeholder="Mobile Number" label="mobile number">
                                    <vue-tel-input :value="form.mobile_number" @input="handlePhoneNumber" @country-changed="handleCountryChange"></vue-tel-input>
                                </Field>
                            </div>
                            <small>Phone number with country code ex. +1XXXXXX6789</small><br />
                            <ErrorMessage name="mobile_number" class="text-danger" />
                        </div>
                    </div>
                    <div class="create_btn mt-3">
                        <button class="primary_btn" :disabled="accountCreateLoader">
                            <template v-if="accountCreateLoader">
                                <i class="fa fa-spin fa-spinner"></i>&nbsp; Processing
                            </template>
                            <template v-else>
                                <span>CONTINUE WITH PHONE NUMBER</span>
                            </template>
                        </button>
                    </div>
                </div>
            </Form>
            <div class="login_btn">
                Don't have an account yet? <router-link :to="{ name: 'Register' }">Get started here</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { VueTelInput } from 'vue3-tel-input'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import 'vue3-tel-input/dist/vue3-tel-input.css'

    export default {
        name: 'Change Account Mobile Phone',

        data () {
            return {
                form: {
                    mobile_number: '',
                    unsupported_country: false,
                },
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
            VueTelInput,
        },

        computed: mapState({
            accountCreateLoader: state => state.registerModule.accountCreateLoader,
            acountDetails: state => state.registerModule.acountDetails,
        }),

        mounted () {
            const vm = this;

            vm.getAccountDetail(vm.$route.params.token).then((result) => {
                if (!result) {
                    const options = Helper.swalWarningOptions('Oops!', 'The account token is invalid or expired');

                    Swal.fire(options).then((ev) => {
                        if (ev.isConfirmed) {
                            vm.$router.push({ name: 'Register' });
                        }
                    });
                }
            });
        },

        methods: {
            ...mapActions({
                changeAccountPhone: 'registerModule/changeAccountPhone',
                getAccountDetail: 'registerModule/getAccountDetail',
            }),

            handleChangePhone (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                params.setFieldError = setFieldError;
                params.token = vm.$route.params.token;

                if (vm.$route.query && vm.$route.query.plan) {
                    params.plan = vm.$route.query.plan;
                }

                vm.changeAccountPhone(params).then((result) => {
                    if (result) {
                        vm.$router.push({name: 'VerifyPhone', params: { token: result.token }, query: { plan: vm.$route.query.plan }});
                    }
                });
            },

            handlePhoneNumber (phone, phoneObject, input) {
                const vm = this;

                if (phoneObject && phoneObject.number) {
                    vm.form.mobile_number = phoneObject.number
                }
            },

            handleCountryChange (countryObj) {
                const vm = this;
                const availableCountries = ['US', 'UK', 'GB', 'CA', 'AU'];
                const element = document.querySelector('.vue-tel-input input');

                if (availableCountries.includes(countryObj.iso2)) {
                    if (element) {
                        element.disabled = false;
                        element.placeholder = 'Enter a phone number';
                        vm.form.unsupported_country = false;
                    }
                } else {
                    if (element) {
                        element.disabled = true;
                        element.placeholder = 'Unsupported Country';
                        vm.form.unsupported_country = true;
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .verify_account {
        height: 100vh;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .verify_account .area_content {
        max-width: 500px;
        width: 100%;
        text-align: left;
    }

    .verify_account .area_content .logo {
        margin: 0 0 40px;
    }

    .verify_account .area_content .logo img {
        max-width: 200px;
        height: auto;
        width: 100%;
    }

    .verify_account h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        color: #121525;
        margin-bottom: 10px;
    }

    .verify_account h5 {
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 500;
        max-width: 350px;
    }

    .verify_account .verify_form {
        border: 1px solid #e9e9e9;
        background: #f5f5f5;
        border-radius: 8px;
        padding: 20px 40px 45px 40px;
        margin-top: 30px;
    }

    .verify_account .verify_form .field_wpr {
        background: #fff;
    }

    .verify_account .more_action {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
    }

    .verify_account .more_action li {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        cursor: pointer;
    }

    .verify_account .more_action li i {
        margin-right: 7px;
    }

    .verify_account .primary_btn {
        position: relative;
        height: 50px;
        cursor: pointer;
    }

    .verify_account .primary_btn span {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }

    .verify_account .primary_btn .suffix {
        position: absolute;
        right: 70px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
    }

    .verify_account h5 {
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 500;
        text-align: center;
        position: relative;
        max-width: 100%;
    }

    .verify_account h5:after {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        width: 100%;
        border-bottom: 1px solid #d9d9d9;
        z-index: 0;
    }

    .verify_account h5 span {
        position: relative;
        z-index: 1;
        padding: 0 15px;
        background: #fafaf5;
    }

    .verify_account .login_btn {
        display: flex;
        justify-content: center;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
        padding: 20px 0;
    }

    .verify_account .login_btn a {
        text-decoration: none;
        color: #2f7eed;
        padding: 0 5px;
    }

    .verify_account .primary_btn .prefix {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
    }

    .verify_account .primary_btn .fa-spinner.prefix {
        position: absolute;
        left: 30px;
        top: 30%;
        transform: translateY(-50%);
    }

    :deep(.g-btn-wrapper) {
        width: 100%;
    }
</style>
